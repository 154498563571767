import * as React from "react"
import {reactLocalStorage} from 'reactjs-localstorage';
import TokenStatus from '../components/admin/TokenStatus';
import {apiGetWithAuth, admin_verify} from '../api';

const defaultValues = {
  stepsLabels: ['Configure', 'Payment method', 'Review order'],
  stepsLength: 3,
  cartProduct: {},
  activeStep: 0,
  contentLoading: true,
  btnLoading: true,
  orderContext:{},
  tokenStatus: TokenStatus.Finding,
  email: null,
  token: null,

  setContentLoading: ()=>{},
  setBtnLoading: ()=>{},
  setCartProduct: () => {},
  setActiveStep: ()=>{},
  setOrderContext: ()=>{},
  logout: ()=>{},
  login: ()=>{},
  isAdmin: ()=>{},
}

export const StoreContext = React.createContext(defaultValues)

const localStorageKey = `buybycrypto_checkout`

function isUserLoggedIn(setEmail, setToken)
{
    const email = reactLocalStorage.get("email", "");
    const token = reactLocalStorage.get("token", "");
    if(email === "" || token === "") return false;
    setEmail(email);
    setToken(token);
    return true;
}

export const StoreProvider = ({ children }) => {
  const [email, setEmail] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [contentLoading, setContentLoading] = React.useState(true);
  const [btnLoading, setBtnLoading] = React.useState(true);
  const [cartProduct, setCartProduct_] = React.useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [orderContext, setOrderContext] = React.useState({});
  const [tokenStatus, setTokenStatus] = React.useState(TokenStatus.Finding);

  React.useEffect(()=>{
    if(!isUserLoggedIn(setEmail, setToken)) {
      setTokenStatus(TokenStatus.NotFound);
      return;
    }
    else {
      setTokenStatus(TokenStatus.Found);
    }

    const product_ = localStorage.getItem(localStorageKey);
    if(product_) setCartProduct_(JSON.parse(product_));
    setTimeout(()=>{ 
        setContentLoading(false);
        setBtnLoading(false);
    }, 1000);
  }, [setCartProduct_, setContentLoading, setBtnLoading, localStorageKey]);

  const setCartProduct = (product) => {
    localStorage.setItem(localStorageKey, JSON.stringify(product))
  }

  const logout = () => {
    reactLocalStorage.remove("token");
    reactLocalStorage.remove("email");
    window.location.assign("/");
  };

  const login = (email, token) => {
    reactLocalStorage.set("token", token);
    reactLocalStorage.set("email", email);
    window.location.assign("/");
  }

  const isAdmin = React.useCallback(async function () {
    if(tokenStatus === TokenStatus.Finding || tokenStatus === TokenStatus.NotFound) return false;

    if(email === null || token === null) return false;
  
    const response = await apiGetWithAuth(admin_verify, email, token);
    return response?.is_admin === true;
  }, [email, token, tokenStatus]);

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        setCartProduct,
        cartProduct,
        activeStep,
        contentLoading,
        btnLoading,
        orderContext,
        tokenStatus,
        email,
        token,

        setContentLoading,
        setBtnLoading,
        setCartProduct,
        setActiveStep,
        setOrderContext,
        logout,
        login,
        isAdmin
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
