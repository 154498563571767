import axios from 'axios';
import {show_error} from '../components/toast';

const api_address = `https://api.buybycrypto.shop`;
// const api_address = `http://127.0.0.1`;

export const api = axios.create({
    baseURL: api_address,
    headers: {
      'Content-Type': 'application/json'
    }
});

export const apiWithAuth = (email, token) => {
  return axios.create({
    baseURL: api_address,
    headers: {
      'Authorization': 'bearer',
      'token': token, 
      'email': email,
      "Content-type": "application/json; charset=UTF-8"
    }
  });
}

export const apiPostWithAuth = async (url, body, email, token) => {
  try {
    const api = apiWithAuth(email, token);
    const result  = await api.post(url, body);
    if(result.data.status === "success") return result.data;
    else show_error({message: result.data.message});
  } catch (err) {
    show_error({message: err.response?.data?.message ?? err.message });
  }
  return null;
};

export const apiPutWithAuth = async (url, body, email, token) => {
  try {
    const api = apiWithAuth(email, token);
    const result  = await api.put(url, body);
    if(result.data.status === "success") return result.data;
    else show_error({message: result.data.message});
  } catch (err) {
    show_error({message: err.response?.data?.message ?? err.message });
  }
  return null;
};

export const apiDeleteWithAuth = async (url, body, email, token) => {
  try {
    const api = apiWithAuth(email, token);
    const result  = await api.delete(url, body);
    if(result.data.status === "success") return result.data;
    else show_error({message: result.data.message});
  } catch (err) {
    show_error({message: err.response?.data?.message ?? err.message });
  }
  return null;
};

export const apiGetWithAuth = async (url, email, token) => {
  try {
    const api = apiWithAuth(email, token);
    const result  = await api.get(url);
    if(result.data.status === "success") return result.data;
    else show_error({message: result.data.message});
  } catch (err) {
    show_error({message: err.response?.data?.message ?? err.message });
  }
  return null;
};

export async function api_post(url, body)
{
  try {
    const result  = await api.post(url, body);
    if(result.data.status === "success") return result.data;
    else show_error({message: result.data.message});
  } catch (err) {
    show_error({ message: err.response?.data?.message ?? err.message });
  }
  return null;
}

export const create_auth_challenge_url  = '/users/create_auth_challenge';
export const solve_auth_challenge_url   = '/users/solve_auth_challenge';
export const admin_verify               = '/users/is_admin';
export const admin_verified_users_url   = '/users/count';
export const admin_send_email_url       = '/users/send_email';

export const invoices_url               = '/invoices';
export const pay_invoice_url            = '/invoices/pay';
export const confirm_pay_invoice_url    = '/invoices/confirm_pay';
export const reject_pay_invoice_url     = '/invoices/reject_pay';

export const servers_url                = '/products/servers';
export const server_actions_url         = '/products/servers/actions';
export const domains_url                = '/products/domains';
export const domain_actions_url         = '/products/domains/actions';
export const gift_cards_url             = '/products/gift_cards';
export const vpns_url                   = '/products/vpns';
export const free_vpns_url              = '/products/free-vpns';
export const exchange_rates_url         = '/exchange_rates';

export const support_ticket_url         = '/support_tickets';
export const close_support_ticket_url   = '/support_tickets/close';
export const reject_support_ticket_url  = '/support_tickets/reject';

export const refund_ticket_url          = '/refund_tickets';
export const close_refund_ticket_url    = '/refund_tickets/close';
export const reject_refund_ticket_url   = '/refund_tickets/reject';

export const admin_renew_invoice_url    = '/admin/invoices/pending/renew';
export const admin_active_services_url  = '/admin/services/active';
export const admin_expired_services_url = '/admin/services/expired';
