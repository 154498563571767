exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-admin-active-servers-js": () => import("./../../../src/pages/admin/active-servers.js" /* webpackChunkName: "component---src-pages-admin-active-servers-js" */),
  "component---src-pages-admin-add-domain-js": () => import("./../../../src/pages/admin/add_domain.js" /* webpackChunkName: "component---src-pages-admin-add-domain-js" */),
  "component---src-pages-admin-add-free-vpn-js": () => import("./../../../src/pages/admin/add-free-vpn.js" /* webpackChunkName: "component---src-pages-admin-add-free-vpn-js" */),
  "component---src-pages-admin-add-gift-card-js": () => import("./../../../src/pages/admin/add_gift_card.js" /* webpackChunkName: "component---src-pages-admin-add-gift-card-js" */),
  "component---src-pages-admin-add-server-js": () => import("./../../../src/pages/admin/add_server.js" /* webpackChunkName: "component---src-pages-admin-add-server-js" */),
  "component---src-pages-admin-add-vpn-js": () => import("./../../../src/pages/admin/add-vpn.js" /* webpackChunkName: "component---src-pages-admin-add-vpn-js" */),
  "component---src-pages-admin-cmd-hist-js": () => import("./../../../src/pages/admin/cmd-hist.js" /* webpackChunkName: "component---src-pages-admin-cmd-hist-js" */),
  "component---src-pages-admin-create-invoice-js": () => import("./../../../src/pages/admin/create_invoice.js" /* webpackChunkName: "component---src-pages-admin-create-invoice-js" */),
  "component---src-pages-admin-domain-actions-js": () => import("./../../../src/pages/admin/domain_actions.js" /* webpackChunkName: "component---src-pages-admin-domain-actions-js" */),
  "component---src-pages-admin-exchange-rates-js": () => import("./../../../src/pages/admin/exchange_rates.js" /* webpackChunkName: "component---src-pages-admin-exchange-rates-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-pending-invoices-js": () => import("./../../../src/pages/admin/pending_invoices.js" /* webpackChunkName: "component---src-pages-admin-pending-invoices-js" */),
  "component---src-pages-admin-refund-tickets-js": () => import("./../../../src/pages/admin/refund_tickets.js" /* webpackChunkName: "component---src-pages-admin-refund-tickets-js" */),
  "component---src-pages-admin-send-email-js": () => import("./../../../src/pages/admin/send_email.js" /* webpackChunkName: "component---src-pages-admin-send-email-js" */),
  "component---src-pages-admin-server-actions-js": () => import("./../../../src/pages/admin/server_actions.js" /* webpackChunkName: "component---src-pages-admin-server-actions-js" */),
  "component---src-pages-admin-support-tickets-js": () => import("./../../../src/pages/admin/support_tickets.js" /* webpackChunkName: "component---src-pages-admin-support-tickets-js" */),
  "component---src-pages-myaccount-checkout-js": () => import("./../../../src/pages/myaccount/checkout.js" /* webpackChunkName: "component---src-pages-myaccount-checkout-js" */),
  "component---src-pages-myaccount-index-js": () => import("./../../../src/pages/myaccount/index.js" /* webpackChunkName: "component---src-pages-myaccount-index-js" */),
  "component---src-pages-myaccount-invoices-js": () => import("./../../../src/pages/myaccount/invoices.js" /* webpackChunkName: "component---src-pages-myaccount-invoices-js" */),
  "component---src-pages-myaccount-services-domains-js": () => import("./../../../src/pages/myaccount/services/domains.js" /* webpackChunkName: "component---src-pages-myaccount-services-domains-js" */),
  "component---src-pages-myaccount-services-gift-cards-js": () => import("./../../../src/pages/myaccount/services/gift_cards.js" /* webpackChunkName: "component---src-pages-myaccount-services-gift-cards-js" */),
  "component---src-pages-myaccount-services-index-js": () => import("./../../../src/pages/myaccount/services/index.js" /* webpackChunkName: "component---src-pages-myaccount-services-index-js" */),
  "component---src-pages-myaccount-services-servers-js": () => import("./../../../src/pages/myaccount/services/servers.js" /* webpackChunkName: "component---src-pages-myaccount-services-servers-js" */),
  "component---src-pages-myaccount-signin-js": () => import("./../../../src/pages/myaccount/signin.js" /* webpackChunkName: "component---src-pages-myaccount-signin-js" */),
  "component---src-pages-myaccount-support-js": () => import("./../../../src/pages/myaccount/support.js" /* webpackChunkName: "component---src-pages-myaccount-support-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-product-detail-js": () => import("./../../../src/templates/product-detail.js" /* webpackChunkName: "component---src-templates-product-detail-js" */),
  "component---src-templates-product-type-js": () => import("./../../../src/templates/product-type.js" /* webpackChunkName: "component---src-templates-product-type-js" */)
}

