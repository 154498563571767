import * as React from "react"
import Toastify from 'toastify-js'

// import { toastWrapper, hiding, showing } from "./toast.module.css"

export function show_error({message})
{
    Toastify({
        text: `Error: ${message}`,
        duration: 3000
      }).showToast();
}

export function show_message({message})
{
    Toastify({
        text: `${message}`,
        duration: 3000
      }).showToast();
}
